<template>
  <div>
    <Crumbs></Crumbs>
    <div class="mainList">
      <div class="mainList_operation clearfloat">
        <div class="mainList_operation_inline_block">
          <div class="mainList_operation_search">
            <div class="search_center">
              <span> 项目名称：</span>
              <el-select
                v-model="parameter.projectId"
                clearable
                filterable
                placeholder="请输入或选择项目名称"
                class="ipt_width project_width"
                @change="dataUpdate"
              >
                <el-option
                  v-for="item in projectList"
                  :key="item.id"
                  :label="item.projectName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>

            <div class="search_center">
              <span>部门：</span>
              <DeptCascader
                :placeholder="'请选择部门'"
                :deptList="deptList"
                v-model="parameter.deptClassifyId"
              />
            </div>
            <div class="search_center">
              <span>填报时间：</span>
              <el-date-picker
                class="date_width"
                v-model="createdDate"
                :pickerOptions="pickerOptions"
                type="daterange"
                range-separator="至"
                start-placeholder="填报时间（始）"
                end-placeholder="填报时间（末）"
              >
              </el-date-picker>
            </div>

            <el-button type="primary" icon="el-icon-search" @click="pageChangeHandler(1)"
              >搜索</el-button
            >
            <el-button type="warning" icon="el-icon-refresh" @click="handleReset">清空</el-button>
          </div>
        </div>
        <div class="fr">
          <el-button type="primary" @click="dialogVisible = true">申请流程</el-button>
        </div>
      </div>

      <div class="mainList_content">
        <div class="mainList_content_sub">
          <el-table
            ref="multipleTable"
            row-key="id"
            border
            :data="tableData"
            :default-expand-all="true"
            tooltip-effect="dark"
            height="string"
            v-loading="loading"
          >
            <el-table-column align="center" type="index" width="50" label="序号"></el-table-column>
            <el-table-column
              align="center"
              prop="projectName"
              sortable
              min-width="280"
              :show-overflow-tooltip="false"
              label="项目名称"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="110"
              prop="projectSource"
              label="项目来源"
            >
              <template slot-scope="scope">
                {{ scope.row.projectSource | dict(dictData.projectSource) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="110"
              prop="businessLines"
              label="业务种类"
            >
              <template slot-scope="scope">
                {{ scope.row.businessLines | dict(dictData.businessLines) }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              min-width="110"
              sortable
              prop="deptClassifyName"
              label="部门"
            ></el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="110"
              prop="createName"
              label="项目经理"
            >
            </el-table-column>
            <el-table-column
              align="center"
              :show-overflow-tooltip="false"
              sortable
              min-width="130"
              prop="productPerformance"
              label="生产绩效"
            >
              <template slot-scope="scope">
                {{ scope.row.productPerformance | applyAmount }}
              </template></el-table-column
            >
            <el-table-column
              align="center"
              min-width="130"
              sortable
              prop="taskDate"
              label="填报时间"
            >
              <template slot-scope="scope">
                {{ scope.row.taskDate | dateFormat }}
              </template>
            </el-table-column>

            <el-table-column
              align="center"
              sortable
              min-width="150"
              prop="statusName"
              label="方案进度"
            >
            </el-table-column>

            <el-table-column prop="title" min-width="70" fixed="right" align="center" label="操作">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  class="text_Details_Bgc"
                  @click="detail(scope.row)"
                  >详情</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="pageChangeHandler"
            :current-page="parameter.pageNow"
            :page-size="parameter.pageSize"
            prev-text="上一页"
            next-text="下一页"
            layout="total, prev, pager, next, slot, jumper"
            :total="parameter.total"
          >
            <span class="el-pagination__jump e_a_pagination">
              <el-input size="mini" v-model.number="pageSize" @blur="handlePageSize"></el-input>
              <span style="padding-top: 1px">条/页</span>
            </span>
          </el-pagination>
        </div>
      </div>
    </div>
    <Edit v-if="showEdit" :isShow.sync="showEdit" :getTitle="getTitle" :options="form"></Edit>

    <el-dialog width="75%" title="生产绩效分配方案流程图" :visible.sync="dialogVisible">
      <div style="padding: 5px">
        <img src="@/assets/生产绩效分配方案流程图.png" alt="生产绩效分配方案流程图" width="100%" />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Upload: () => import('@/components/upload/Upload.vue'),
    Edit: () => import('../Edit.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    DeptCascader: () => import('@/components/dept/DeptCascader.vue'),
  },
  data() {
    return {
      parameter: {
        projectId: '',
        deptClassifyId: '',
        pageNow: 1,
        pageSize: 50,
        total: 0,
      },
      pageSize: 0,
      tableData: [],
      loading: false,
      showEdit: false,
      dialogVisible: false,
      form: {},
      projectList: [], // 项目数据
      deptList: [], // 部门 列表
      createdDate: [],
      getTitle: '',
      dictData: {
        projectSource: [],
        businessLines: [],
      },
      pickerOptions: {
        onPick: v => {
          if (v.minDate && !v.maxDate) {
            this.$set(this.createdDate, 0, v.minDate.getTime())
            this.$set(this.createdDate, 1, '')
          }
        },
      },
    }
  },
  provide() {
    return {
      re: this.re,
    }
  },
  watch: {
    createdDate: {
      deep: true,
      handler: function (val) {
        if (val == null) {
          this.createdDate = []
        }
      },
    },
  },

  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
    }),
  },
  created() {
    this.getProjectList() /** 加载 "项目" 数据 **/
    this.getDeptList() /** 加载 "部门" 列表数据 **/
    this.pageSize = this.userInfo.pageSizeLog
    this.parameter.pageSize = this.userInfo.pageSizeLog
    this.getData()
    this.$api.dict
      .listSysDictData('PROJECT_SOURCE', true)
      .then(res => {
        this.dictData.projectSource = res.data
      })
      .catch(err => {
        console.log(err)
      })

    this.$api.dict
      .listSysDictData('BUSINESS_LINES', true)
      .then(res => {
        this.dictData.businessLines = res.data
      })
      .catch(err => {
        console.log(err)
      })
  },
  filters: {
    applyAmount(n) {
      if (!n) return
      return Number(n).toLocaleString()
    },
  },
  methods: {
    getData() {
      this.loading = true

      if (this.createdDate && this.createdDate != []) {
        this.parameter.startDate = new Date(this.createdDate[0]).getTime()
        this.parameter.endDate = new Date(this.createdDate[1]).getTime()
      } else if (this.createdDate == null) {
        this.parameter.startDate = ''
        this.parameter.endDate = ''
      }
      this.$api.projectPerformance
        .allList(this.parameter)
        .then(res => {
          this.loading = false
          if (res.data == []) {
            this.tableData = res.data
          } else {
            this.tableData = res.data ? (res.data.records ? res.data.records : []) : []
            this.parameter.total = res.data ? (res.data.total ? res.data.total : 0) : 0
          }
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    pageChangeHandler(val) {
      this.parameter.pageNow = val
      this.getData()
    },
    handleSizeChange(val) {
      this.parameter.pageSize = val
      this.parameter.pageNow = 1
      this.getData()
    },
    //全局分页保存
    handlePageSize() {
      if (this.userInfo.pageSizeLog == this.pageSize) {
        return
      }
      if (!this.pageSize) {
        this.pageSize = 1
      }
      let obj = { pageSizeLog: this.pageSize }
      this.loading = true
      this.$api.common
        .saveStaffPageSizeLog(obj)
        .then(res => {
          this.loading = false
          this.parameter.pageSize = this.pageSize
          this.userInfo.pageSizeLog = this.pageSize
          localStorage.setItem('userInfo', JSON.stringify(this.userInfo))
          this.getData()
        })
        .catch(err => {
          console.log(err)
          this.loading = false
        })
    },
    detail(row) {
      this.getTitle = '绩效分配方案详情'
      this.form = row.deepClone()
      this.form.list_s = 4
      this.showEdit = true
    },
    re() {
      this.form = {}
      this.getData()
      this.showEdit = false
    },
    /** 加载 "项目" 数据 **/
    getProjectList() {
      this.$api.project
        .projectList()
        .then(res => {
          this.projectList = res.data ? res.data : []
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**  点击 "重置" 按钮 **/
    handleReset() {
      this.parameter.projectId = ''
      this.parameter.deptClassifyId = ''
      this.parameter.startDate = ''
      this.parameter.endDate = ''
      this.createdDate = []
      this.parameter.pageNow = 1
      this.getData() /** 加载 "付款申请审核" 数据 **/
    },
    /** 加载 "部门" 列表数据 **/
    getDeptList() {
      this.$api.sysDept
        .getTreeDept()
        .then(res => {
          this.deptList = res.data ? res.data : []
          this.$store.commit({
            type: 'business/SET_DEPT_LIST',
            selectDeptList: res.data,
          })
        })
        .catch(err => {
          console.log(err)
        })
    },
    dataUpdate() {
      this.$forceUpdate()
    },
  },
}
</script>
<style scoped lang="scss">
@import '@/styles/config.scss';
.iconCost {
  padding-left: 10px;
  font-size: 28px;
  vertical-align: middle;
  cursor: pointer;
}
</style>
